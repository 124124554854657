import Card from '../Card/Card'

import './Boxes.css'

const Boxes = ({ items }) => {
  return (
    <div className='boxes'>
      {items.map(item => (
        <div className='item' key={item.id}>
          <Card item={item} key={item.id} />
        </div>
      ))}
    </div>
  )
}

export default Boxes
