import HeroBanner from '../Components/HeroBanner/HeroBanner'
import Boxes from '../Components/Boxes/Boxes'
import MetaTags from 'react-meta-tags'
import BoxesData from '../Components/Boxes/BoxesData'

const Home = () => {
  return (
    <>
      <MetaTags>
        <title>Specialister i Tiny Houses</title>
        <meta name='description' content='Some description.' />
        <meta property='og:title' content='MyApp' />
        <meta property='og:image' content='path/to/image.jpg' />
      </MetaTags>
      <HeroBanner
        headline='Specialister i Tiny Houses'
        text='Oplev den ultimative frihed og fleksibilitet med vores skræddersyede tiny houses.'
        link='huse'
        button='Se alle vores huse'
        image='https://www.tinyhouse-company.dk/media/81/f1/30/1693569040/photo_2023-09-01%2014.49.39.jpeg'
      />
      <Boxes items={BoxesData} />
    </>
  )
}

export default Home
