import { useParams } from 'react-router-dom'

import HousesData from '../Components/Houses/HousesData'

const House = () => {
  const { slug } = useParams()
  const item = HousesData.find(houseItem => houseItem.url == slug)

  return (
    <div className='house'>
      <img src={item.image} alt={item.name} />
      <h1>{item.title}</h1>
      <div className='subtitle'>{item.subtitle}</div>
      <div
        className='desc'
        dangerouslySetInnerHTML={{
          __html: item.text
        }}
      ></div>
    </div>
  )
}

export default House
