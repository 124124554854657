import { Link } from 'react-router-dom'

import './HeroBanner.css'

const HeroBanner = ({ headline, text, link, button, image }) => {
  return (
    <div className='hero-banner'>
      <div
        className='image'
        style={{
          backgroundImage: 'url(' + image + ')'
        }}
      ></div>
      <div className='text'>
        {headline && <div className='headline'>{headline}</div>}
        {text && <p>{text}</p>}
        {link && button && (
          <p>
            <Link to={link} className='button'>
              {button}
            </Link>
          </p>
        )}
      </div>
    </div>
  )
}

export default HeroBanner
