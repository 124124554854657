import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import Default from './Layout/Default'
import Home from './Pages/Home'
import Houses from './Pages/Houses'
import House from './Pages/House'
import About from './Pages/About'
import Faq from './Pages/Faq'

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path='/' Component={Default}>
          <Route index element={<Home />} />
          <Route path='huse' element={<Houses />} />
          <Route path='huse/:slug' element={<House />} />
          <Route path='om-os' element={<About />} />
          <Route path='faq' element={<Faq />} />
        </Route>
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
)
