import NavigationItem from './NavigationItem'

import './Navigation.css'
import NavigationData from './NavigationData'

const Navigation = () => {
  return (
    <nav>
      <ul>
        {NavigationData.map(item => (
          <NavigationItem item={item} key={item.id} />
        ))}
      </ul>
    </nav>
  )
}

export default Navigation
