const BoxesData = [
  {
    id: 1,
    title: 'Drømmen om et tiny house',
    text: '<p>Forestil dig at vågne op hver morgen omgivet af naturens skønhed, hvor hver kvadratmeter af dit hjem er designet til at maksimere komfort og funktionalitet. Drømmen om et tiny house handler ikke kun om at bo småt, men om at leve stort med færre begrænsninger og mere frihed.</p><p>Det er en livsstil, hvor enkelhed og bæredygtighed går hånd i hånd, og hvor dit hjem kan være lige så mobil som dine drømme. Et tiny house giver dig mulighed for at fokusere på det, der virkelig betyder noget – oplevelser, relationer og frihed til at udforske verden omkring dig.</p>',
    image:
      'https://storage.googleapis.com/stateless-designerstuen-dk/Orchid-Tiny-House-p%C3%A5-gr%C3%A6s.jpg',
    link: '',
    button: '',
    teaser: false
  },
  {
    id: 2,
    title: 'Vi støtter dig hele vejen',
    text: '<p>At skifte til et tiny house er en stor beslutning, og vi forstår, at det kræver både overvejelser og planlægning. Hos tinyHouse er vi dedikerede til at støtte dig gennem hele processen, fra den første idé til det øjeblik, du flytter ind i dit nye hjem.</p><p>Vores erfarne team står klar til at besvare dine spørgsmål, hjælpe dig med at vælge den rette model og vejlede dig gennem tilpasningsmulighederne, så dit tiny house bliver præcis, som du ønsker det. Vi tilbyder også finansieringsløsninger, der gør det nemmere for dig at realisere din drøm. Og vores support stopper ikke, når du modtager nøglerne.</p>',
    image:
      'https://storage.googleapis.com/stateless-designerstuen-dk/Tiny-House-med-House-Doctor.jpg',
    link: '',
    button: '',
    teaser: false
  },
  {
    id: 3,
    title: '25 års garanti',
    text: '<p>Vi står fuldt ud inde for kvaliteten og holdbarheden af vores tiny houses. Derfor tilbyder vi en imponerende 25 års garanti på alle vores modeller. Vores tiny houses er bygget med de gode materialer og mest avancerede byggeteknikker, hvilket sikrer, at de kan modstå tidens tand og de skiftende årstider.</p><p>Med vores 25 års garanti kan du have fuld tillid til, at dit hjem ikke kun er smukt og funktionelt, men også bygget til at holde. Skulle der mod forventning opstå problemer, er vores dedikerede serviceafdeling altid klar til at assistere dig, så du hurtigt kan komme tilbage til at nyde dit hjem.</p>',
    image:
      'https://assets.hansgrohe.com/celum/web/hgmag_design_tiny_house_4x3.jpg',
    link: '',
    button: '',
    teaser: false
  }
]

export default BoxesData
