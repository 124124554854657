import { NumericFormat } from 'react-number-format'

import ImageAlbatros from './Assets/Albatros.jpg'
import ImageFalcon from './Assets/Falcon.jpg'

const HousesData = [
  {
    id: 1,
    title: 'Falcon',
    subtitle: (
      <NumericFormat
        value='120000'
        displayType='text'
        thousandSeparator='.'
        decimalSeparator=','
        renderText={value => 'Fra ' + value + 'DKK'}
      />
    ),
    text: '<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus risus urna, cursus vitae tellus in, ornare faucibus nibh. Aliquam metus est, fermentum id semper a, luctus nec est. Mauris quis maximus nulla.</p><p>Cras iaculis, lorem tempor volutpat lobortis, lacus massa luctus nunc, eu varius enim neque id mi. Vestibulum diam diam, molestie a facilisis ac, vulputate ut est. Proin varius luctus varius. Nam eget ipsum blandit metus consectetur tempus. Phasellus ac maximus eros. Donec id feugiat velit, vitae vulputate velit. Duis ac feugiat nisl, sed malesuada dui. Pellentesque sodales quis massa sit amet condimentum. Curabitur tincidunt facilisis placerat. Vestibulum fringilla id nunc vitae iaculis. Cras sit amet nulla nulla. Nam sit amet leo quis orci malesuada euismod et a dui. Nulla semper lorem ac est cursus pharetra. Ut non tellus urna. Quisque lobortis est eget ex sodales gravida. Mauris aliquam non elit eu cursus. Integer ex arcu, posuere id semper et, egestas nec ante. Mauris quis nunc lacus. Aenean diam enim, bibendum in ante eget, sollicitudin consectetur ante. Nulla condimentum tellus magna, vel eleifend arcu aliquam in.</p>',
    image: ImageAlbatros,
    price: 120000,
    url: 'falcon',
    teaser: true
  },
  {
    id: 2,
    title: 'Albatros',
    subtitle: (
      <NumericFormat
        value='159000'
        displayType='text'
        thousandSeparator='.'
        decimalSeparator=','
        renderText={value => 'Fra ' + value + 'DKK'}
      />
    ),
    text: '<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus risus urna, cursus vitae tellus in, ornare faucibus nibh. Aliquam metus est, fermentum id semper a, luctus nec est. Mauris quis maximus nulla.</p><p>Cras iaculis, lorem tempor volutpat lobortis, lacus massa luctus nunc, eu varius enim neque id mi. Vestibulum diam diam, molestie a facilisis ac, vulputate ut est. Proin varius luctus varius. Nam eget ipsum blandit metus consectetur tempus. Phasellus ac maximus eros. Donec id feugiat velit, vitae vulputate velit. Duis ac feugiat nisl, sed malesuada dui. Pellentesque sodales quis massa sit amet condimentum. Curabitur tincidunt facilisis placerat. Vestibulum fringilla id nunc vitae iaculis. Cras sit amet nulla nulla. Nam sit amet leo quis orci malesuada euismod et a dui. Nulla semper lorem ac est cursus pharetra. Ut non tellus urna. Quisque lobortis est eget ex sodales gravida. Mauris aliquam non elit eu cursus. Integer ex arcu, posuere id semper et, egestas nec ante. Mauris quis nunc lacus. Aenean diam enim, bibendum in ante eget, sollicitudin consectetur ante. Nulla condimentum tellus magna, vel eleifend arcu aliquam in.</p>',
    image: ImageFalcon,
    price: 159000,
    url: 'albatros',
    teaser: true
  }
]

export default HousesData
