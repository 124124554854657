import HeroBanner from '../Components/HeroBanner/HeroBanner'

import '../Components/About/About.css'

const Home = () => {
  return (
    <>
      <h1>Om os</h1>
      <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus congue
        porta dictum. Proin venenatis placerat mauris, id aliquam nisl cursus
        iaculis. Cras mollis nibh ac efficitur varius. Vestibulum scelerisque
        aliquet tristique. Sed euismod ante tincidunt nisi maximus, at gravida
        sem volutpat. Maecenas feugiat est ut erat gravida, varius porttitor ex
        mollis. Praesent pharetra faucibus mauris vitae dignissim.
      </p>

      <div className='about-hero'>
        <HeroBanner image='https://cdn.syd.bazo.dk/images/52c986b8-50a8-47a1-a03f-b520b3a7124b' />
      </div>
    </>
  )
}

export default Home
