const Question = ({ item }) => {
  return (
    <div className='question'>
      <div className='q'>{item.question}</div>
      <div className='a'>{item.answer}</div>
    </div>
  )
}

export default Question
