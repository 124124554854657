const FaqData = [
  {
    id: 1,
    title: 'Gruppe',
    text: 'Beskrivelse af gruppe',
    questions: [
      {
        id: 1,
        question: 'Spørgsmål 1',
        answer: 'Svar 1'
      },
      {
        id: 2,
        question: 'Spørgsmål 2',
        answer: 'Svar 2'
      }
    ]
  }
]

export default FaqData
