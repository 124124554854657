import Logo from '../Logo/Logo'

import './Footer.css'

const Footer = () => {
  return (
    <footer>
      <Logo />

      <div className='columns'>
        <div className='column'>
          <ul>
            <li>
              <a href=''>Huse</a>
            </li>
            <li>
              <a href=''>Om os</a>
            </li>
            <li>
              <a href=''>FAQ</a>
            </li>
          </ul>
        </div>
      </div>

      <p>
        <strong>TinyHouse</strong> Adressevej 20, 3480 Bynavn - CVR: 12345678
      </p>
    </footer>
  )
}

export default Footer
