const NavigationData = [
  {
    id: 2,
    name: 'Huse',
    slug: 'huse'
  },
  {
    id: 3,
    name: 'Om os',
    slug: 'om-os'
  },
  {
    id: 4,
    name: 'FAQ',
    slug: 'faq'
  }
]

export default NavigationData
