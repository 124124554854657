import { Link } from 'react-router-dom'

import './Card.css'

const Card = ({ item }) => {
  const textMaxLength = 100

  const cardItem = (
    <>
      <div
        className='image'
        style={{ backgroundImage: 'url(' + item.image + ')' }}
      ></div>
      <div className='body'>
        <div className='title'>{item.title}</div>
        {item.subtitle && <div className='subtitle'>{item.subtitle}</div>}

        <div
          className='text'
          dangerouslySetInnerHTML={{
            __html: !item.teaser
              ? item.text
              : `${item.text.slice(0, textMaxLength)}...`
          }}
        ></div>
      </div>
    </>
  )

  if (item.url) {
    return (
      <Link to={item.url} className='card'>
        {cardItem}
      </Link>
    )
  }

  return <div className='card'>{cardItem}</div>
}

export default Card
