import Card from '../Components/Card/Card'

import HousesData from '../Components/Houses/HousesData'

import '../Components/Houses/Houses.css'

const Houses = () => {
  return (
    <>
      <h1>Vores total fede huse</h1>
      <p>
        Nulla vulputate justo at efficitur gravida. Curabitur fermentum
        tincidunt nunc, convallis elementum ligula. Mauris ut efficitur arcu,
        nec lacinia lectus. Fusce interdum commodo sapien, eu pulvinar nisi
        malesuada sit amet. Proin venenatis augue nulla, ac sodales urna rutrum
        ut. Suspendisse vel nunc ornare sapien imperdiet facilisis. Phasellus
        sit amet consectetur nibh. Suspendisse potenti. Integer sed risus
        commodo, luctus leo finibus, scelerisque ipsum. Phasellus bibendum
        tincidunt nunc ut ultrices. Nulla accumsan aliquet enim ut fermentum.
        Proin ut mauris vel urna volutpat imperdiet a et ipsum.
      </p>

      <div className='houses'>
        {HousesData.map(item => (
          <div className='item' key={item.id}>
            <Card item={item} />
          </div>
        ))}
      </div>
    </>
  )
}

export default Houses
