import FaqGroup from '../Components/Faq/FaqGroup'

import FaqData from '../Components/Faq/FaqData'
import '../Components/Faq/Faq.css'

const Faq = () => {
  return (
    <>
      <h1>FAQ</h1>
      <p>Find svar på de mest spurgte spørgsmål hos vores kunder.</p>
      <div className='faq-groups'>
        {FaqData.map(item => (
          <FaqGroup item={item} key={item.id} />
        ))}
      </div>
    </>
  )
}

export default Faq
