import { Link } from 'react-router-dom'

const NavigationItem = ({ item }) => {
  return (
    <li>
      <Link to={item.slug}>{item.name}</Link>
    </li>
  )
}

export default NavigationItem
