import { Link } from 'react-router-dom'

import './Logo.css'

const Logo = () => {
  return (
    <Link to='/' className='logo'>
      <div className='top'>TINY</div>
      <div className='bottom'>House</div>
    </Link>
  )
}

export default Logo
