import Navigation from './Navigation'
import Logo from '../Logo/Logo'

import './Header.css'

const Header = () => {
  return (
    <header>
      <div className='inner'>
        <Logo />
        <Navigation />
      </div>
    </header>
  )
}

export default Header
