import Question from './Question'

const FaqGroup = ({ item }) => {
  return (
    <div className='group'>
      <div className='title'>{item.title}</div>
      <div className='description'>{item.text}</div>
      {item.questions && (
        <div className='questions'>
          {item.questions.map(question => (
            <Question item={question} key={question.id} />
          ))}
        </div>
      )}
    </div>
  )
}

export default FaqGroup
